@if (iconClass) {
<i [ngClass]="iconClass"
  [style.color]="iconColor"></i>
}
<span class="vi-text"
  class="size-{{size}}"
  [ngClass]="{'with-inline': inline}"
  [style.color]="color"
  [attr.aria-label]="text"
  [attr.title]="text">
  {{text}}
</span>
