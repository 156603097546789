export enum EventCategory {
  ACCOUNTS = 'Accounts',
  AMS_MIGRATION = 'Migration',
  AUTH = 'Auth',
  HTTP = 'HTTP',
  // for backward compatibility
  AZURE_CONNECT = 'azure_connect',
  CUSTOMIZATION = 'Customization',
  DOWNLOAD = 'Download',
  EDITOR = 'Editor',
  EXCEPTIONS = 'Exceptions',
  FACE_GATE = 'FaceGate',
  GALLERY = 'Gallery',
  GENERAL = 'General',
  HEADER = 'Header',
  INDEXING_FORM = 'IndexingForm',
  INSIGHTS = 'Insights',
  INVITE = 'Invite',
  MEDIA = 'Media',
  NOTIFICATIONS = 'Notifications',
  DIRECTORIES = 'Directories',
  EDGE = 'Edge',
  SUPPORTED_LANGUAGES = 'SupportedLanguages',
  PLAYER = 'Player',
  CORE_PLAYER = 'Core_Player',
  EDITOR_CORE_PLAYER = 'Editor_Core_Player',
  PRESET = 'Preset',
  SDK = 'SDK',
  SETTINGS = 'Settings',
  SHELL = 'Shell',
  SIDE_BAR = 'SideBar',
  STRIP = 'Strip',
  TIMELINE = 'Timeline',
  // for backward compatibility
  TIMELINE_DATA = 'Timeline-data',
  UPLOAD = 'Upload',
  RE_INDEX = 'ReIndex',
  INDEXING_SETTINGS = 'IndexingSettings',
  INDEXING_REVIEW = 'IndexingReview',
  USER_SETTINGS = 'UserSettings',
  USERS = 'Users',
  Language_ID = 'LanguageId',
  TEXTUAL_SUMMARY = 'TextualSummary',
  LIVE = 'Live'
}
