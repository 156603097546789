import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { guid } from '../../utils';
import { IconProps, InputType } from './intefaces';

@Component({
  selector: 'vi-ui-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent {
  @Input() type: InputType = InputType.Text;
  @Input() label: string;
  @Input() value: string | number;
  @Input() placeholder: string = '';
  @Input() id: string = guid();
  @Input() disabled: boolean = false;

  @Input() min?: number;
  @Input() max?: number;

  @Input() icon: IconProps;
  @Input() errorMessage: string;
  @Input() required: boolean;
  @Input() maxLength: number;
  @Output() inputChanged = new EventEmitter<string>();

  public get error() {
    return !!this.errorMessage;
  }
}
