import { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Action } from '@ngrx/store';

import { filter } from 'rxjs/operators';
import { OperatorFunction } from 'rxjs';

import { GuidPattern, VideoIdPattern } from '@common/modules/utils/regexes';

import { IRouterStateUrl, LIVE_ROUTER_NAVIGATION } from '../interfaces';

// ofRoute function allow us to filter ROUTER_NAVIGATION action to a specific route.
export function ofRoute(route: string | string[]): OperatorFunction<RouterNavigationAction<IRouterStateUrl>, Action> {
  return filter((action: Action) => {
    const isRouteAction = action.type === ROUTER_NAVIGATION || action.type === LIVE_ROUTER_NAVIGATION;
    if (isRouteAction) {
      const routeAction = action as RouterNavigationAction;
      const routePath = routeAction.payload.routerState.url?.split('?')[0];

      const routesArray = Array.isArray(route) ? route : [route];

      // Check if one of the routes is valid
      for (const currentRoute of routesArray) {
        if (currentRoute === routePath) {
          return true;
        }

        if (currentRoute.includes(':accountId')) {
          let formattedRoute = currentRoute.replace(':accountId', GuidPattern);

          if (formattedRoute.includes(':videoId')) {
            formattedRoute = formattedRoute.replace(':videoId', VideoIdPattern);
          }

          if (formattedRoute.includes(':extensionId')) {
            formattedRoute = formattedRoute.replace(':extensionId', GuidPattern);
          }

          const regex = new RegExp(`${formattedRoute}$`, 'i');
          if (routePath.match(regex) !== null) {
            return true;
          }
        }
      }

      return false;
    }
    return isRouteAction;
  });
}

export function formatRoute(route: string): string {
  let formattedRoute = route;
  if (route.includes(':accountId')) {
    formattedRoute = route.replace(':accountId', GuidPattern);

    if (formattedRoute.includes(':videoId')) {
      formattedRoute = formattedRoute.replace(':videoId', VideoIdPattern);
    }
  }
  return formattedRoute;
}
