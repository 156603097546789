import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { Subject, take, takeUntil } from 'rxjs';

import { FocusableComponent } from '../focusable';
import { TextComponent } from '../text';

@Component({
  selector: 'vi-ui-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent extends FocusableComponent implements OnDestroy {
  @Input() text: string;
  @Input() href: string = 'javascript:void(0)';
  @Input() role: string = 'link';
  @Input() target: string = '_blank';
  @Input() size: TextComponent['size'];
  @Input() inline?: boolean = false;
  @Input() iconClass?: string;
  @Input() iconColor: string = 'var(--primary)';
  @Input() color: string = 'var(--primary)';
  @Input() highContrast?: boolean = false;
  @Input() highContrastColor?: string = 'var(--primary-press)';
  @Output() linkClicked = new EventEmitter<string>();

  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {
    super();
    this.accessibilityModeSubject.pipe(takeUntil(this.destroy$), take(1)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get linkColor(): string {
    if (this.highContrast && this.highContrastColor) {
      return this.highContrastColor;
    }
    return this.color;
  }
}
