import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';

import { AlertMessageType } from '@vi-ui/core';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { resources } from './resources';
import { IAccountUnavailableData } from './interfaces';

@Component({
  selector: 'vi-account-unavailable-dialog',
  templateUrl: './account-unavailable-dialog.component.html',
  styleUrls: ['./account-unavailable-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountUnavailableDialogComponent implements OnInit {
  @Input() public data: IAccountUnavailableData;

  public AlertMessageType = AlertMessageType;
  public resources = resources;

  constructor(private translate: TranslateHelperService, private trackService: TrackService) {}

  ngOnInit(): void {
    this.trackService.track('account_unavailable_dialog.init', { category: EventCategory.ACCOUNTS });
    this.initTranslations();
  }

  private initTranslations() {
    this.translate.translateResourcesInstant(this.resources);
  }
}
