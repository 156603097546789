export const resources = {
  TabsBrands: '',
  TabsLanguage: '',
  TabsFaces: '',
  TabsLanguageID: '',
  HeaderContentModelCustomization: '',
  SpeechNew: '',
  LanguageClassic: '',
  LiveModelCatalogTitle: ''
};
