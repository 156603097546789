import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Optional } from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { MediaCommonStoreService } from '@common/modules/media-data-common/services/media-common-store.service';

import { TrackService } from '../../../core/services/track';
import { InsightsCommonUtilsService } from '../../../insights-common/insights-common-utils.service';
import { ISubscriptions } from '../../../insights/interfaces';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { resources } from './resources';
import { SearchbarService } from './services/searchbar.service';

@Component({
  selector: 'app-vi-searchbar',
  templateUrl: './searchbar.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() public status: string;
  @Input() public isStatusClickable: boolean;
  @Input() public isDisabled: boolean;
  @Input() public isFocusout: boolean;
  @Input() public placeholder: string;
  @Input() public id = 'searchbar';
  @Input() public delay = 800;
  @Input() public initialSearchValue = '';
  // Outputs
  @Output() public statusChanged = new EventEmitter<void>();
  @Output() public focusSearch = new EventEmitter<boolean>();
  @Output() public inputChanged = new EventEmitter<string>();

  @HostBinding('class.focused') isFocused: boolean = false;
  // Public
  public resources = resources;
  public searching = false;

  // Privates
  private subscriptions: ISubscriptions = {};
  // Ctor
  constructor(
    private el: ElementRef,
    private translateHelper: TranslateHelperService,
    private track: TrackService,
    private insightsCommonUtilsService: InsightsCommonUtilsService,
    private searchbarService: SearchbarService,
    private liveAnnouncer: LiveAnnouncer,
    @Optional() private mediaCommonStoreService: MediaCommonStoreService
  ) {}

  public ngOnInit() {
    // Init tabs
    this.translateHelper.translateResources(this.resources);
    this.setSearchSubscription();
  }

  public setSearchSubscription() {
    // Create observable from event
    const inputElement = this.el.nativeElement.querySelector('input');
    const source = fromEvent<KeyboardEvent>(inputElement, 'keyup');

    if (this.isFocusout) {
      this.addFocusoutSubscriptions(inputElement);
    }

    // Reset search
    this.searchbarService.lastFilter = '';
    this.searchbarService.updateSearchEvent({ id: this.id, query: '' });

    if (this.initialSearchValue) {
      this.handleSearchEvent(this.initialSearchValue, 'search_by_search_parameter');
    }

    // Create subscription
    this.subscriptions.onSearchType = source
      .pipe(
        map(val => val.target['value']),
        // Filter empty values
        // .pipe(filter(v => v.length))
        // Delay for user typing
        debounceTime(this.delay),
        // Check for distinct typings
        distinctUntilChanged()
      )
      // Search
      // Subscribe to stream
      .subscribe(s => {
        this.handleSearchEvent(s);
      });
  }

  public handleFocus(isFocus: boolean) {
    this.focusSearch.emit(isFocus);
    this.isFocused = isFocus;
  }

  public statusClicked() {
    if (this.isStatusClickable) {
      this.statusChanged.emit();
    }
  }

  public resetInput() {
    const inputElement = this.el.nativeElement.querySelector('input');
    inputElement.value = '';
  }

  public setInput(value: string) {
    const inputElement = this.el.nativeElement.querySelector('input');
    inputElement.value = value;
  }

  public ngOnDestroy() {
    // Unsubscribe all subscriptions.
    this.insightsCommonUtilsService.unsubscribeSubscriptions(this.subscriptions);
  }

  private handleSearchEvent(s: string, trackingName?: string) {
    this.searchbarService.lastFilter = s;
    // Check that the value is not blank spaces
    // And skip if the length is zero length
    if (s.length !== 0 && s.trim().length === 0) {
      return;
    }
    this.searchbarService.updateSearchEvent({ id: this.id, query: s.trim() });
    this.searching = false;

    this.liveAnnouncer.announce(this.resources.SearchCompleted);
    // Set event for store
    this.mediaCommonStoreService?.setInsightsFilterValue(s);
    this.inputChanged.emit(s);
  }

  private addFocusoutSubscriptions(inputElement) {
    const focus = fromEvent<FocusEvent>(inputElement, 'blur');

    // Create subscription
    this.subscriptions.onSearchTypeFocusout = focus
      .pipe(
        map(val => val.target['value']),
        // Filter empty values
        // .pipe(filter(v => v.length))
        // Delay for user typing
        debounceTime(800),
        // Check for distinct typings
        distinctUntilChanged()
      )
      // Search
      // Subscribe to stream
      .subscribe(s => {
        this.handleSearchEvent(s);
        this.handleFocus(false);
      });
  }
}
