import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';

import { switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, tap } from 'rxjs';

import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { CustomizationPage, FeatureSwitch } from '@common/modules/core/services/interfaces';
import { StripService } from '@common/modules/shared/components/strip/strip.service';
import { MessageType } from '@common/modules/shared/components/strip/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { TRANSLATION_DELAY } from '@common/modules/translation/variables';

import { SpeechPageStoreService } from './../services/speech/speech-page-store.service';
import { VIRoutingMap } from '../../app/routing/routes';
import * as AccountsActions from '../../core/actions/accounts.actions';
import * as CustomizationViewActions from '../../core/actions/customization-view.actions';
import { IState } from '../../core/reducers';
import * as fromRouter from '../../core/reducers/router';
import { ofRoute } from '../../core/services/operations';
import * as BrandActions from '../actions/brands-model.actions';
import * as LogoGroupsActions from '../actions/logo-groups.actions';
import * as LanguageActions from '../actions/language-model.actions';
import * as PersonActions from '../actions/person-model.actions';
import * as UnknownPersonActions from '../actions/people/unknown-person.actions';
import * as SpeechDatasetsActions from '../actions/speech/speech-datasets.actions';
import * as SpeechModelsActions from '../actions/speech/speech-models.actions';
import * as SpeechPageActions from '../actions/speech/speech-page.actions';
import * as LanguageIdActions from '../actions/language-id.actions';
import * as SupportedLanguagesIdsActions from '../actions/supported-languages-ids.actions';
import { SpeechPermission } from '../interfaces';
import { resources } from '../resources/languages-resources';
import { selectSupportedLanguagesIdsError } from '../selectors';

@Injectable()
export class CustomizationEffects {
  public customizationRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      ofRoute([
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.catalog.path}`,
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationPeople.path}`,
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationPeople.path}/${VIRoutingMap.customizationPeopleUnknown.path}`,
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationLanguage.path}`,
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationBrands.path}`,
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationSpeech.path}`,
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationSpeech.path}/${VIRoutingMap.customizationSpeechDatasets.path}`,
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationSpeech.path}/${VIRoutingMap.customizationSpeechModels.path}`,
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationLanguageId.path}`
      ]),
      withLatestFrom(this.store.select(fromRouter.getRouterState)),
      switchMap(([, routerState]) => {
        const customizationPath = routerState.state.data.path;
        switch (customizationPath) {
          case VIRoutingMap.customizationLanguage.path: {
            return [CustomizationViewActions.initPage({ page: CustomizationPage.Language })];
          }
          case VIRoutingMap.customizationPeople.path: {
            return [CustomizationViewActions.initPage({ page: CustomizationPage.People })];
          }
          case VIRoutingMap.customizationBrands.path: {
            return [CustomizationViewActions.initPage({ page: CustomizationPage.Brands })];
          }
          case VIRoutingMap.customizationSpeech.path: {
            this.handleSpeechRoute();
            return EMPTY;
            break;
          }
          case VIRoutingMap.customizationLanguageId.path: {
            const isLanguageIdEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.AutoDetectLanguage);
            if (isLanguageIdEnabled) {
              return [CustomizationViewActions.initPage({ page: CustomizationPage.LanguageId })];
            }
            break;
          }
          case VIRoutingMap.catalog.path: {
            const isLive = this.featureSwitchService.featureSwitch(FeatureSwitch.LiveEdge);
            if (isLive) {
              return [CustomizationViewActions.initPage({ page: CustomizationPage.Catalog })];
            }
            break;
          }
        }
        return EMPTY;
      })
    )
  );

  public clearCustomization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.selectAccountProcessing, AccountsActions.selectArmAccountProcessing),
      withLatestFrom(this.store.select(selectSupportedLanguagesIdsError)),
      switchMap(([, isSupportedLanguagesError]) => {
        this.hideLanguagesErrorStrip(isSupportedLanguagesError);

        return [
          LanguageActions.clearLanguageModels(),
          BrandActions.clearBrandModels(),
          LogoGroupsActions.clearLogoGroups(),
          PersonActions.clearPersonModels(),
          SpeechModelsActions.clearSpeechModels(),
          SpeechDatasetsActions.clearSpeechDatasets(),
          LanguageIdActions.clearLanguageIdDatasets(),
          UnknownPersonActions.clearUnknownPersons(),
          SpeechPageActions.initSpeechPagePermission({ permission: SpeechPermission.UNDEFINED })
        ];
      })
    )
  );

  public setErrorLoadingLanguages = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          LanguageActions.failLoadLanguageModels,
          SpeechModelsActions.loadSpeechModelsFailed,
          SupportedLanguagesIdsActions.loadSupportedLanguageFailed
        ),
        tap(() => {
          this.showLanguagesErrorStrip();
        })
      ),
    { dispatch: false }
  );

  private languagesErrorStripId: number;

  constructor(
    private actions$: Actions,
    private store: Store<IState>,
    private featureSwitchService: FeatureSwitchService,
    private speechPageStoreService: SpeechPageStoreService,
    private stripService: StripService,
    private translate: TranslateHelperService
  ) {
    setTimeout(() => {
      this.init();
    }, TRANSLATION_DELAY);
  }

  private init() {
    this.translate.translateResourcesInstant(resources);
  }

  private hideLanguagesErrorStrip(isSupportedLanguagesError) {
    if (this.languagesErrorStripId && !isSupportedLanguagesError) {
      this.stripService.hideStrip(this.languagesErrorStripId);
      this.languagesErrorStripId = null;
    }
  }

  private showLanguagesErrorStrip() {
    if (!this.languagesErrorStripId) {
      this.languagesErrorStripId = this.stripService.trigger({
        messageType: MessageType.WARNING,
        text: resources?.LanguagesLoadingFailErrorMsg,
        show: true,
        iconClass: 'i-vi-warning'
      });
    }
  }

  private handleSpeechRoute() {
    this.speechPageStoreService.isSpeechEnabled().subscribe(isSpeechEnabled => {
      if (isSpeechEnabled) {
        this.store.dispatch(CustomizationViewActions.initPage({ page: CustomizationPage.Speech }));
      }
    });
  }
}
