import { Component, Input } from '@angular/core';
@Component({
  selector: 'vi-ui-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent {
  @Input() text: string;
  @Input() size: '12-16' | '13-18' | '14-20' = '14-20';
  @Input() inline?: boolean = false;
  @Input() color?: string = 'var(--type-primary)';
  @Input() iconClass?: string;
  @Input() iconColor?: string = 'var(--type-primary)';
}
