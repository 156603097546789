<cdk-virtual-scroll-viewport [viUiItemSize]="isVirtualScrollActive ? this.rowHeight : null">
  <table mat-table
    matSort
    [ngClass]="{'compact': styleType === ITableStyleType.COMPACT, 'accessible': accessibilityMode}"
    (matSortChange)="sortData($event)"
    [dataSource]="dataSource">

    <!--items columns definitions-->
    <ng-container *ngFor="let column of columnsConfig; let index = index; trackBy: trackByIndex">
      <ng-container matColumnDef="{{column.key}}">
        <!--column header-->
        <th mat-header-cell
          mat-sort-header="{{index}}"
          [disabled]="!column?.sortable"
          [ngClass]="{'sortable': column?.sortable}"
          [ngStyle]="{'width': column.width}"
          *matHeaderCellDef>
          {{column.name}}
        </th>

        <!--column cell-->
        <td mat-cell
          *matCellDef="let item"
          [ngStyle]="{'width': column.width}">
          <div *ngIf="!column.component"
            class="text-cell">
            {{item[column.key]}}
          </div>


          <div *ngIf="column.component"
            class="{{item}}">
            <ng-container #componentCell></ng-container>
          </div>

        </td>
      </ng-container>
    </ng-container>


    <!--select column definition-->
    <ng-container matColumnDef="{{SELECT_COLUMN}}">
      <th mat-header-cell
        class="select"
        *matHeaderCellDef>
        <vi-ui-rounded-checkbox *ngIf="allowMultiSelect"
          [tabindex]="0"
          [show]="showHeaderSelection"
          [selected]="selection.hasValue() && isAllSelected()"
          (clicked)="toggleAllRows()"
          (keyup.space)="toggleAllRows()"
          (focusout)="showHeaderSelection = false"
          (focus)="showHeaderSelection = true">
        </vi-ui-rounded-checkbox>
      </th>
      <td mat-cell
        *matCellDef="let item; let i = index;"
        class="select">
        <vi-ui-rounded-checkbox [show]="item.isHovered"
          [selected]="selection.isSelected(item)"
          [ariaLabelledby]="'row_' + i"
          (clicked)="toggleRow(item)">
        </vi-ui-rounded-checkbox>
      </td>
    </ng-container>

    <!--row definitions-->
    <tr mat-header-row
      [ngClass]="{'selectable': selectable}"
      *matHeaderRowDef="displayedColumns; sticky: true;"
      (mouseenter)=" headerRowHovered= true"
      (mouseleave)="headerRowHovered = false">
    </tr>
    <tr mat-row
      [ngClass]="{'selectable': selectable, 'selected': selectable && selection.isSelected(row)}"
      tabindex="0"
      *matRowDef="let row; let i = index; columns: displayedColumns;"
      id="{{'row_' + i}}"
      (keyup.space)="selectable? selection.toggle(row): null"
      (mouseenter)="setItemHovered(row, true)"
      (mouseleave)="setItemHovered(row, false)"
      (focusout)="setItemHovered(row, false)"
      (focus)="setItemHovered(row, true)">
    </tr>
  </table>
  @if (isLoading) {
  <div class="loading">
    <div class="rotating">
      <i class="i-vi-loading"></i>
    </div>
  </div>
  }
</cdk-virtual-scroll-viewport>