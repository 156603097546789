import { UIInviteActionType } from 'src/apps/web/src/invite';
import { UICameraActionType } from 'src/apps/web/src/edge/live/components/live-camera-management/components/live-camera-item/interfaces';

import { UIActionType } from '../insights/interfaces';
import { UIEditorActionType } from '../editor/interfaces';
import { UISearchActionType } from './components/searchbar/interfaces';
import { UIHeaderActionType } from '../../../apps/web/src/header/components/header/interfaces';
import { UIIndexingActionType } from '../../../apps/web/src/indexing/components/shared/settings/interfaces';
import { UIHelpActionType } from '../../../apps/web/src/sidebar/components/help/interfaces';
import { UIMediaActionType } from '../../../apps/web/src/media/pages/media/interfaces';
import { UIShellActionType } from '../../../apps/web/src/shell/interfaces';
import { UINotificationsActionType } from '../../../apps/web/src/sidebar/interfaces';
import { ActionButtonType } from './components/action-button/interfaces';
import { UIGalleryActionType, UIGalleryTabsActionType } from '../../../apps/web/src/gallery/core/models/gallery';
import { UILanguageModelActionType, UILanguageModelFileActionType } from '../../../apps/web/src/customization/interfaces';
import { UIGalleryMatchActionType } from '../../../apps/web/src/gallery/components/search-view/gallery-search-matches/interfaces';
import { UIDownloadActionType } from './components/download-menu/interfaces';
import { UserType } from '../auth/interfaces';

export interface IAction {
  /**
   * Is this action is the main action, relevant in case of the action with actionList.
   */
  isButtonAction?: boolean;
  /**
   * the key is used for localization.
   */
  key?: string;
  /**
   * the left icon of the action. used for button / drop down
   */
  icon?: string;
  /**
   * The text that is rendered - should be translated.
   */
  title?: string;
  /**
   * Determine the [title] on the button. need to be localized.
   */
  titleAttribute?: string;
  /**
   * if the action is selected or not. used for preserve the select state.
   */
  selected?: boolean;
  /**
   * type of the action
   */
  type?: UIActionTypeOptions;
  /**
   * the value, can be anything ,depends of the context for example en-US or 'save' or 1/0.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  /**
   * is the action is selectable and preserved its state, for example 'edit' mode.
   */
  selectable?: boolean;
  /**
   * the id of the action.
   */
  id: string;
  /**
   * If the action has drop down or not, used for accessibility
   */
  hasDrop?: boolean;
  /**
   * If the action has press mode or not, used for accessibility
   */
  hasPressedMode?: boolean;
  /**
   * If the action is reflects at the drop down selection after selecting. example privacy drop
   */
  applyValue?: boolean;
  /**
   * Is action disabled
   */
  isDisabled?: boolean;
  /**
   * Action element style
   */
  style?: string;
  /**
   * Is action link
   */
  isLink?: boolean;
  /**
   * Group by option - the group name.
   */
  group?: string;
  /**
   * Group by option - the group name key for translation.
   */
  groupKey?: string;
  /**
   * Button Type
   */
  buttonType?: ActionButtonType;
  /**
   * href for link
   */
  href?: string;
  /**
   * Is separated. It is for action in menu
   */
  separated?: boolean;
  /**
   * placeholder
   */
  placeholder?: string;
  /**
   * Is action is in preview mode
   */
  preview?: boolean;
  /**
   * In case the UI value is different than the API value
   */
  label?: string;
}

export interface IGroupAction extends IAction {
  isGroup: boolean;
}

export type UIActionTypeOptions =
  | UIActionType
  | UIEditorActionType
  | UIHeaderActionType
  | UIIndexingActionType
  | UIHelpActionType
  | UIMediaActionType
  | UISearchActionType
  | UIGalleryActionType
  | UILanguageModelActionType
  | UILanguageModelFileActionType
  | UIGalleryTabsActionType
  | UIShellActionType
  | UIInviteActionType
  | UIGalleryMatchActionType
  | UIDownloadActionType
  | UINotificationsActionType
  | UICameraActionType;

export interface IVIBaseCompliant {
  setAccessibility(): void;
  setLocalization(): void;
}

export interface IBrowserDetails {
  browser: string;
  version: number;
  isApproved: boolean;
  os: string;
}

export enum VideoModerationState {
  OK = 'OK',
  EXPLICIT = 'Explicit',
  REMOVED = 'Removed',
  NONE = 'None'
}

export enum StreamingPreset {
  SingleBitrate = 'SingleBitrate',
  AdaptiveBitrate = 'AdaptiveBitrate',
  NoStreaming = 'NoStreaming',
  Default = 'Default'
}

export enum VideoReviewState {
  NONE = 'None',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed'
}

export enum ActionButtonResponsiveStatesClass {
  EDITOR_RESPONSIVE = 'editor-responsive',
  INSIGHTS_RESPONSIVE = 'insights-responsive'
}

export enum ActionableInputResponsiveStatesClass {
  EDITOR_RESPONSIVE_INPUT = 'editor-responsive-input'
}

export interface IComponentChange<T, P extends keyof T> {
  previousValue: T[P];
  currentValue: T[P];
  firstChange: boolean;
}

export type ComponentChanges<T> = {
  [P in keyof T]?: IComponentChange<T, P>;
};

export interface IThumbStyle {
  style: IStyle;
  url: string;
  position: number;
}

export interface IStyle {
  backgroundImage: string;
  backgroundPosition: string;
}

export interface IArrToString {
  name: string;
}

export enum Direction {
  LEFT = 'left',
  RIGHT = 'right'
}

export enum StorageCacheKey {
  LoginHint = 'VILoginHint',
  Locale = 'vi.locale',
  LastAccountType = 'vi.accountType',
  LastAccountId = 'vi.lastAccount',
  LastArmSubscriptionId = 'vi.lastArmSubscriptionId',
  LastArmResourceGroup = 'vi.lastArmResourceGroup',
  LastArmAccountName = 'vi.lastArmAccountName',
  LastArmAccount = 'vi.lastArmAccount',
  LastExtension = 'vi.lastExtension',
  ShowEdgeExtensions = 'vi.showEdgeExtensions',
  LastTimeShowSurvey = 'vi.lastTimeShowSurvey',
  DoNotShowFaceGatePopup = 'vi.DoNotShowFaceGatePopup',
  ShowFaceGatePopupCounter = 'vi.ShowFaceGatePopupCounter',
  DoNotShowSurvey = 'vi.doNotShowSurvey-12-22',
  NotificationsLastSeenDate = 'vi.notificationsLastSeenDate',
  NotificationsRegionalLastSeenDate = 'vi.notificationsRegionalLastSeenDate',
  NotificationsETag = 'vi.notificationsETag',
  NotificationsIds = 'vi.notificationsIds',
  NotificationsToastedIds = 'vi.notificationsToastedIds',
  NotificationsRegionalIds = 'vi.notificationsRegionalIds',
  NotificationsRegionalToastedIds = 'vi.notificationsRegionalToastedIds',
  NotificationsAccountTypeIds = 'vi.notificationsAccountTypeIds',
  NotificationsAccountTypeToastedIds = 'vi.notificationsAccountTypeToastedIds',
  LastTenantId = 'vi.lastTenantId',
  LanguageId = 'vi.languageId'
}

export type DeepPartial<T> = {
  [propertyKey in keyof T]?: DeepPartial<T[propertyKey]>;
};

export enum IndexState {
  Uploaded = 'Uploaded',
  Processing = 'Processing',
  Processed = 'Processed',
  Failed = 'Failed',
  Quarantined = 'Quarantined',
  Deleting = 'Deleting'
}

export enum TextDirection {
  RTL = 'rtl',
  LTR = 'ltr'
}

export interface ElementSize {
  height: number;
  width: number;
}

export enum AccountPermission {
  OWNER = 'Owner',
  CONTRIBUTOR = 'Contributor',
  READER = 'Reader',
  MY_ACCESS_MANAGER = 'MyAccessManager',
  RESTRICTED_VIEWER = 'RestrictedViewer'
}

export interface AccountAccessTokenClaims {
  Permission: AccountPermission;
  AccountId?: string;
  ExternalUserId?: string;
  UserType?: UserType;
}

export enum TranslationJobState {
  VIDEO_TRANSLATION_FAILED = 'VIDEO_TRANSLATION_FAILED',
  TRANSLATION_NOT_FOUND = 'TRANSLATION_NOT_FOUND',
  VIDEO_PROCESSING = 'VIDEO_PROCESSING'
}

export interface IUIAccountRoleAssignment extends Microsoft.VideoIndexer.Contracts.AccountRoleAssignment {
  removing?: boolean;
  selectedRoleAction?: IAction;
}
